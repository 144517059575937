import React, { memo, useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { CsImageModal, DeliveryForRiderModal, EditCsModal } from "@/Modals";
import { formatDate, getDateByTimeZone } from "@/lib/date";

import { TYPE_CHAINLOGIS, TYPE_USER } from "@/store/Cses";
import { useCsesStore, useModalStore, useLoadingStore } from "@/store/hooks";

const CsesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const ImageWrap = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 200px;
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const CheckWrap = styled.div``;
const CreatedAtWrap = styled.div``;

const StyledTabs = styled(Tabs)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const CheckColumn = memo(({ id }) => {
  const { ...actions } = useCsesStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleClickConfirm = useCallback(async () => {
    if (window.confirm("단순 처리하시겠습니까?")) {
      try {
        await actions.check(id);
        handleUpdate();
      } catch (e) {
        window.alert(`CS 처리에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.check, id]);

  return <SmallButton onClick={handleClickConfirm}>완료</SmallButton>;
});

const RevertCheckColumn = memo(({ id, bookId }) => {
  const { ...actions } = useCsesStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleClickConfirm = useCallback(async () => {
    if (window.confirm(`${bookId} CS완료를 취소하시겠습니까?`)) {
      try {
        await actions.revertCheck(id);
        handleUpdate();
      } catch (e) {
        window.alert(`CS 처리에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.revertCheck, id, bookId]);

  return <SmallButton onClick={handleClickConfirm}>취소</SmallButton>;
});

const RenderCheckColumn = memo(({ cs }) => {
  return (
    <CheckWrap>
      {cs.checked && cs.completedAt ? (
        <>
          {formatDate(getDateByTimeZone(cs.completedAt), "yyyy/MM/dd HH:mm")}
          <br />({cs.withDeliveryCompleted ? "배송완료" : "단순완료"})
        </>
      ) : (
        ""
      )}
    </CheckWrap>
  );
});

const CreatedAtColumn = memo(({ cs }) => {
  return (
    <CreatedAtWrap>
      {formatDate(getDateByTimeZone(cs.createdAt), "yyyy/MM/dd HH:mm")}
      {cs.updateLastLog && cs.updateLastLog.admin ? (
        <>
          <br />
          최근수정일시:{" "}
          {formatDate(
            getDateByTimeZone(new Date(cs.updateLastLog.updatedAt)),
            "yyyy/MM/dd HH:mm",
          )}
          <br />
          수정자: {cs.updateLastLog.admin.realname}
        </>
      ) : (
        ""
      )}
    </CreatedAtWrap>
  );
});

const EditColumn = memo(({ cs }) => {
  const { ...actions } = useCsesStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  function handleClickEdit() {
    openModal(
      <EditCsModal key="edit-cs-modal" onUpdate={handleUpdate} cs={cs} />,
    );
  }

  return (
    <SmallButton variant="warning" onClick={handleClickEdit}>
      수정
    </SmallButton>
  );
});

const ImageColumn = ({ image }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(<CsImageModal key="cs-image-modal" image={image} />);
  }

  return (
    <ImageWrap onClick={handleClick}>
      <Image src={image} />
    </ImageWrap>
  );
};

const BookIdColumn = ({ bookId, type = "1" }) => {
  const { openModal } = useModalStore();

  const onAfterUpdate = () => {};

  function handleClick() {
    openModal(
      <DeliveryForRiderModal
        backdrop
        key="book-detail-modal"
        bookId={bookId}
        onAfterUpdate={onAfterUpdate}
        type={type}
        prevPage="CS"
      />,
    );
  }

  return <ClickableColumn onClick={handleClick}>{bookId}</ClickableColumn>;
};

export default () => {
  const { state, ...actions } = useCsesStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register } = useForm();

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page,
    state.query.pageSize,
    state.query.checked,
    state.query.spotName,
    state.query.bookId,
    state.query.deliveryRiderName,
    state.query.tab,
    state.query.deleted,
    state.query.orderBy,
  ]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `CS 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  const handleSetChecked = useCallback(
    (checked) => {
      actions.setQuery({
        checked,
        deleted: false,
        page: 1,
      });
    },
    [actions.setQuery],
  );

  const handleSetDeleted = useCallback(() => {
    actions.setQuery({
      checked: null,
      deleted: true,
      page: 1,
    });
  }, [actions.setQuery]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        accessor: (row) => (
          <BookIdColumn
            bookId={row.bookId}
            type={row.type}
            status={row.status}
          />
        ),
        selectable: false,
        width: 160,
      },
      {
        Header: "사측주문번호",
        accessor: (row) => row.deliveryOrderIdFromCorp,
        selectable: false,
        width: 100,
      },
      {
        Header: "송하인명",
        accessor: (row) => row.deliverySenderName,
        selectable: false,
        width: 100,
      },
      {
        Header: "CS 내용",
        accessor: (row) => row.text,
        selectable: false,
        width: 300,
        ellipsis: false,
      },
      {
        Header: "기사님 답변",
        accessor: (row) => row.answer,
        selectable: false,
        width: 300,
        ellipsis: false,
      },
      {
        Header: "이미지",
        accessor: (row) =>
          row.image ? <ImageColumn image={row.image} /> : null,
        selectable: false,
        width: 220,
      },
      {
        id: "createdAt",
        Header: "CS 등록일",
        accessor: (row) => <CreatedAtColumn cs={row} />,
        selectable: false,
        ellipsis: false,
        sortable: true,
      },
      {
        Header: "처리 여부",
        accessor: (row) => (row.checked ? "O" : "X"),
        selectable: false,
        width: 80,
      },
      {
        id: "completedAt",
        Header: "처리 일시",
        accessor: (row) => <RenderCheckColumn cs={row} />,
        selectable: false,
        sortable: true,
        width: 130,
      },
      {
        Header: "배송주소",
        accessor: (row) => row.receiverAddress,
        selectable: false,
      },
      {
        Header: "배송 라이더",
        accessor: (row) =>
          `${row.deliveryRiderName}(${row.deliveryRiderMobile})`,
        selectable: false,
        width: 160,
      },
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => <EditColumn cs={row} />,
        selectable: false,

        width: 70,
      },
      {
        Header: "완료취소",
        accessor: (row) => (
          <RevertCheckColumn id={row.id} bookId={row.bookId} />
        ),
        selectable: false,

        width: 70,
      },
      {
        Header: "단순완료",
        accessor: (row) => <CheckColumn id={row.id} />,
        selectable: false,

        width: 70,
      },
    ],
    [],
  );

  const onSubmit = useCallback(
    ({ spotName, bookId, deliveryRiderName }) => {
      actions.setQuery({ spotName, bookId, deliveryRiderName });
    },
    [actions.setQuery],
  );

  const handleSelectTabs = useCallback((e) => {
    actions.setQuery({ tab: e });
  }, []);

  return (
    <Container fluid>
      <Title>CS 관리</Title>
      <StyledTabs
        defaultActiveKey={state.query.tab}
        transition={false}
        onSelect={handleSelectTabs}
      >
        <Tab eventKey={TYPE_USER} title="고객"></Tab>
        <Tab eventKey={TYPE_CHAINLOGIS} title="회사"></Tab>
      </StyledTabs>
      <SearchRow>
        <ButtonWrap>
          <StyledButton
            onClick={() => handleSetChecked(null)}
            variant={
              !state.query.deleted && state.query.checked === null
                ? "info"
                : "light"
            }
          >
            전체
          </StyledButton>
          <StyledButton
            onClick={() => handleSetChecked(false)}
            variant={
              !state.query.deleted && state.query.checked === false
                ? "info"
                : "light"
            }
          >
            처리대기
          </StyledButton>
          <StyledButton
            onClick={() => handleSetChecked(true)}
            variant={
              !state.query.deleted && state.query.checked === true
                ? "info"
                : "light"
            }
          >
            처리완료
          </StyledButton>
          <StyledButton
            onClick={handleSetDeleted}
            variant={state.query.deleted === true ? "info" : "light"}
          >
            취소
          </StyledButton>
        </ButtonWrap>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={3}>
              <Form.Control
                name="spotName"
                placeholder="접수점"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={4}>
              <Form.Control
                name="bookId"
                placeholder="배송번호"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={3}>
              <Form.Control
                name="deliveryRiderName"
                placeholder="배송 라이더"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={2}>
              <Button type="submit" style={{ width: "100%" }}>
                조회
              </Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <CsesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.cses}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        previousPage={handlePreviousPage}
        onClickHeader={handleClickHeader}
      />
    </Container>
  );
};
